// ContactForm.js
import React, { useState } from "react";
import "../css/ContactForm.css"; // Add a CSS file for styling (create this file)

const ContactForm = ({ isOpen, onRequestClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    organization: "",
    contactNumber: "",
    region: "",
    inquiryType: "general",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here
    console.log("Form submitted:", formData);
    onRequestClose();
  };

  const handleCancel = () => {
    // Close the form when Cancel is clicked
    onRequestClose();
  };

  return (
    <>
      {isOpen && (
        <div className={`contact-form-modal ${isOpen ? "open" : "closed"}`}>
          <div className="form-container">
          <span className="close" onClick={onRequestClose}>&times;</span>
            <h2>Contact Us</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-row">
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Name*"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                  className="rounded border p-2"
                />
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email*"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  className="rounded border p-2"
                />
              </div>

              <div className="form-row">
                <input
                  type="text"
                  id="organization"
                  name="organization"
                  placeholder="Organization*"
                  value={formData.organization}
                  onChange={handleInputChange}
                  required
                  className="rounded border p-2"
                />
                <input
                  type="tel"
                  id="contactNumber"
                  name="contactNumber"
                  placeholder="Contact Number*"
                  value={formData.contactNumber}
                  onChange={handleInputChange}
                  required
                  className="rounded border p-2"
                />
              </div>

              <div className="form-row">
                <input
                  type="text"
                  id="region"
                  name="region"
                  placeholder="Region*"
                  value={formData.region}
                  onChange={handleInputChange}
                  required
                  className="rounded border p-2"
                />
                {/* Updated: Use a select element for Enquiry Type */}
                <select
                  id="enquiryType"
                  name="enquiryType"
                  value={formData.enquiryType}
                  onChange={handleInputChange}
                  required
                  className="rounded border p-2"
                >
                  <option value="">Select Enquiry Type*</option>
                  <option value="general">General</option>
                  <option value="sales">Sales</option>
                  <option value="support">Support</option>
                  {/* Add more options as needed */}
                </select>
              </div>

              <div className="form-row">
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  placeholder="Message*"
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                  className="rounded border p-2"
                />
              </div>

              <div className="form-row">
                <button type="submit" style={{ backgroundColor: "#26EFE9", borderRadius: "25px", width: "120px", height:"40px", display: "flex", alignItems: "center", justifyContent: "center" }}>
  Submit
  <span style={{ marginLeft: "5px" }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" stroke="black" className="bi bi-play" viewBox="0 0 16 16">
      <path d="M11.742 8L5 4.12v7.76L11.742 8z"/>
    </svg>
  </span>
</button>

              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactForm;
