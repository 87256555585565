// Header.js
import qubiqonLogo from "../assets/images/qubiqon.png";
import React, { useState } from 'react';
import Modal from 'react-modal';
import ContactForm from "./ContactForm";

const Header = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('home');
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };  
  const handleScrollToLocation = (location) => {
    setActiveLink(location);
    const locationElement = document.getElementById(location);
  
    if (locationElement) {
      const offset = 90; // Adjust this value based on your header's height
      const elementPosition = locationElement.offsetTop - offset;
  
      window.scrollTo({
        top: elementPosition,
        behavior: "smooth",
      });
    }
  };
  return (
    <header className="masthead">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Contact Form Modal"
      >
        <ContactForm isOpen={modalIsOpen} onRequestClose={closeModal} />
      </Modal>
      <nav className="navbar navbar-expand-lg navbar-dark fixed-top" style={{backgroundColor:"black"}}>
        <div className="container">
          <a className="navbar-brand" href="#">
            <img
              src={qubiqonLogo}
              style={{ maxWidth: "230px" }}
              alt="Qubiqon Logo"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className={`nav-link ${activeLink === 'home' ? 'active' : ''}`} aria-current-page="true" href="#" onClick={() => setActiveLink('home')}>
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${activeLink === 'about-us' ? 'active' : ''}`} onClick={() => handleScrollToLocation("about-us")}>
                  Company
                </a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${activeLink === 'our-services' ? 'active' : ''}`} onClick={() => handleScrollToLocation("our-services")}>
                  Services
                </a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${activeLink === 'careers' ? 'active' : ''}`} onClick={() => handleScrollToLocation("careers")}>
                  Careers
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={openModal}>
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
          {/* <button type="button" className="btn btnQubiqon">
            Get Started
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
            >
              <rect x="1" y="1" width="20" height="20" rx="10" stroke="white" />
              <path
                d="M15.0211 13.7727L14.798 8.10815C14.7915 8.00261 14.7643 7.89775 14.7179 7.79958C14.629 7.60182 14.4682 7.44104 14.2705 7.35212C14.1723 7.30574 14.0675 7.27855 13.9619 7.27209L8.29738 7.04892C8.19175 7.04476 8.08797 7.06145 7.99198 7.09803C7.89598 7.1346 7.80964 7.19036 7.73789 7.26211C7.59299 7.40701 7.5163 7.60827 7.52471 7.8216C7.53311 8.03493 7.62592 8.24286 7.78271 8.39965C7.9395 8.55644 8.14743 8.64925 8.36076 8.65765L12.0935 8.79906L7.41933 13.4732C7.27502 13.6175 7.19864 13.8179 7.20701 14.0304C7.21539 14.2429 7.30781 14.4499 7.46396 14.6061C7.62012 14.7623 7.8272 14.8547 8.03966 14.863C8.25212 14.8714 8.45256 14.795 8.59687 14.6507L13.271 9.9766L13.4124 13.7093C13.4161 13.815 13.4408 13.9206 13.4849 14.02C13.529 14.1194 13.5918 14.2106 13.6695 14.2883C13.7472 14.366 13.8384 14.4287 13.9378 14.4729C14.0371 14.517 14.1427 14.5416 14.2485 14.5454C14.3542 14.55 14.4582 14.5336 14.5544 14.4972C14.6506 14.4607 14.7371 14.405 14.809 14.3332C14.8808 14.2613 14.9365 14.1748 14.9729 14.0786C15.0094 13.9824 15.0257 13.8785 15.0211 13.7727Z"
                fill="white"
              />
            </svg>
          </button> */}
        </div>
      </nav>
      <div className="bannerBkg"  style={{marginTop:"90px"}}>
        <div className="leftbnr"></div>
        <div className="rightbnr"></div>
        <div className="bnr_bkg_img"></div>

        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-12 text-center">
              <h2 className="bannerh2">
                Navigating Next-Level Innovation in Technology
              </h2>
              <p className="lead text-white">
                If Microsoft technology is a part of your journey, we can help
                you convert its capabilities into business advantage.
              </p>
              <button type="button" className="btn btnWhite">
                Get Started
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                >
                  <rect
                    x="1"
                    y="0.5"
                    width="19"
                    height="19"
                    rx="9.5"
                    stroke="black"
                  />
                  <path
                    d="M14.0211 12.7727L13.798 7.10815C13.7915 7.00261 13.7643 6.89775 13.7179 6.79958C13.629 6.60182 13.4682 6.44104 13.2705 6.35212C13.1723 6.30574 13.0675 6.27855 12.9619 6.27209L7.29738 6.04892C7.19175 6.04476 7.08797 6.06145 6.99198 6.09803C6.89598 6.1346 6.80964 6.19036 6.73789 6.26211C6.59299 6.40701 6.5163 6.60827 6.52471 6.8216C6.53311 7.03493 6.62592 7.24286 6.78271 7.39965C6.9395 7.55644 7.14743 7.64925 7.36076 7.65765L11.0935 7.79906L6.41933 12.4732C6.27502 12.6175 6.19864 12.8179 6.20701 13.0304C6.21539 13.2429 6.30781 13.4499 6.46396 13.6061C6.62012 13.7623 6.8272 13.8547 7.03966 13.863C7.25212 13.8714 7.45256 13.795 7.59687 13.6507L12.271 8.9766L12.4124 12.7093C12.4161 12.815 12.4408 12.9206 12.4849 13.02C12.529 13.1194 12.5918 13.2106 12.6695 13.2883C12.7472 13.366 12.8384 13.4287 12.9378 13.4729C13.0371 13.517 13.1427 13.5416 13.2485 13.5454C13.3542 13.55 13.4582 13.5336 13.5544 13.4972C13.6506 13.4607 13.7371 13.405 13.809 13.3332C13.8808 13.2613 13.9365 13.1748 13.9729 13.0786C14.0094 12.9824 14.0257 12.8785 14.0211 12.7727Z"
                    fill="black"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
