import React from "react";
import DataAndAI from "../assets/images/Data and AI.png";
import Modernization from "../assets/images/Application Modernization.png";
import ITServices from "../assets/images/IT Consultation Services.png";
import Cloud from "../assets/images/Cloud Solutions.png";
const OurServices = () => {
  return (
    <>
      <div
        id="our-services"
        className="container"
        style={{ position: "relative" }}
      >
        <div className="row">
          <div className="col-md-12" style={{ position: "relative" }}>
          <div className="line_bkg_border"style={{top:"510%"}}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="785"
              viewBox="0 0 1287 785"
              fill="none"
              transform="matrix(1,0,0,-1,0,0)"
            >
              <path
                opacity="0.8"
                d="M57.502 782H48.502C23.0969 782 2.50195 761.405 2.50195 736V365C2.50195 339.595 23.0969 319 48.502 319H1238C1263.41 319 1284 298.405 1284 273V49C1284 23.5949 1263.41 3 1238 3H1221.5"
                stroke="url(#paint0_linear_1_32)"
                strokeWidth="5"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1_32"
                  x1="30.0019"
                  y1="30"
                  x2="30.0019"
                  y2="782"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#01002B" />
                  <stop offset="0.0314417" stopColor="#00DE97" />
                  <stop offset="0.959393" stopColor="#050505" />
                  <stop offset="1" stopColor="#01002B" />
                </linearGradient>
              </defs>
            </svg>
          </div>

            <div className="row pb-4" style={{ position: "relative" }}>
              <div className="col-md-8">
                <h4 className="text_h4 px-4">Our Services</h4>
                <p className="px-4">
                  Help you to build a website company that is modern,
                  user-friendly, good SEO, and clean design.
                </p>
              </div>
              <div className="col-md-3 flot-end pt-auto mt-auto">
                <button type="button" className="btn btnWhite float-end">
                  See All{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                  >
                    <rect
                      x="1"
                      y="0.5"
                      width="19"
                      height="19"
                      rx="9.5"
                      stroke="black"
                    />
                    <path
                      d="M14.0211 12.7727L13.798 7.10815C13.7915 7.00261 13.7643 6.89775 13.7179 6.79958C13.629 6.60182 13.4682 6.44104 13.2705 6.35212C13.1723 6.30574 13.0675 6.27855 12.9619 6.27209L7.29738 6.04892C7.19175 6.04476 7.08797 6.06145 6.99198 6.09803C6.89598 6.1346 6.80964 6.19036 6.73789 6.26211C6.59299 6.40701 6.5163 6.60827 6.52471 6.8216C6.53311 7.03493 6.62592 7.24286 6.78271 7.39965C6.9395 7.55644 7.14743 7.64925 7.36076 7.65765L11.0935 7.79906L6.41933 12.4732C6.27502 12.6175 6.19864 12.8179 6.20701 13.0304C6.21539 13.2429 6.30781 13.4499 6.46396 13.6061C6.62012 13.7623 6.8272 13.8547 7.03966 13.863C7.25212 13.8714 7.45256 13.795 7.59687 13.6507L12.271 8.9766L12.4124 12.7093C12.4161 12.815 12.4408 12.9206 12.4849 13.02C12.529 13.1194 12.5918 13.2106 12.6695 13.2883C12.7472 13.366 12.8384 13.4287 12.9378 13.4729C13.0371 13.517 13.1427 13.5416 13.2485 13.5454C13.3542 13.55 13.4582 13.5336 13.5544 13.4972C13.6506 13.4607 13.7371 13.405 13.809 13.3332C13.8808 13.2613 13.9365 13.1748 13.9729 13.0786C14.0094 12.9824 14.0257 12.8785 14.0211 12.7727Z"
                      fill="black"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid" style={{ position: "relative" }}>
          <div className="row px-3 row-flex">
            <div className="col-md-6">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 cardFive">
                    <div className="row py-3">
                      <div className="col-md-6 my-auto py-auto">
                        <div className="ser_top my-1">
                          <p>Development Project</p>
                        </div>
                        <h5 className="serH4">Cloud Solutions</h5>
                      </div>
                      <div className="col-md-6 col-md-6 my-auto py-auto">
                        <img src={Cloud} className="w-100" alt="UI Design" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <h5 className="text_h5">
                      Cloud Solutions for Modern Business
                    </h5>
                    <p className="py-1">
                      <small>
                        We specialize in delivering versatile cloud solutions
                        tailored to meet your unique business needs. Our
                        expertise ensures scalable, secure, and efficient cloud
                        infrastructure, driving innovation and agility across
                        your operations. Choose us to navigate the complexities
                        of cloud technology and unlock new possibilities for
                        growth and efficiency.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 cardFive">
                    <div className="row py-3">
                      <div className="col-md-6 my-auto py-auto">
                        <div className="ser_top my-1">
                          <p>Development Project</p>
                        </div>
                        <h5 className="serH4">Data and AI</h5>
                      </div>
                      <div className="col-md-6 col-md-6 my-auto py-auto">
                        <img src={DataAndAI} className="w-100" alt="UI Design" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <h5 className="text_h5">
                      Data Engineering and AI as Catalysts for Innovation
                    </h5>
                    <p className="py-1">
                      <small>
                        We merge the precision of data engineering with the
                        intelligence of AI to revolutionize your data strategy.
                        Our expertise lies in structuring complex data sets and
                        applying advanced AI analytics, turning data into a
                        powerhouse of insights and opportunities. Elevate your
                        business with our integrated approach to Data
                        Engineering and AI, driving smarter decisions and a
                        distinct competitive advantage.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 cardFive">
                    <div className="row py-3">
                      <div className="col-md-6 my-auto py-auto">
                        <div className="ser_top my-1">
                          <p>Development Project</p>
                        </div>
                        <h5 className="serH4">Application Modernization</h5>
                      </div>
                      <div className="col-md-6 col-md-6 my-auto py-auto">
                        <img src={Modernization} className="w-100" alt="UI Design" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <h5 className="text_h5">
                      Your Comprehensive Partner for Future-Ready Solutions
                    </h5>
                    <p className="py-1">
                      <small>
                        As your end-to-end application modernization partner, we
                        specialize in transforming legacy systems, reducing
                        costs, and preparing your business for the future. Our
                        approach focuses on enhancing operational efficiency and
                        future-proofing your applications, ensuring they are
                        ready for tomorrow's challenges. Embrace innovation with
                        us and stay ahead in the rapidly evolving digital
                        landscape.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 cardFive">
                    <div className="row py-3">
                      <div className="col-md-6 my-auto py-auto">
                        <div className="ser_top my-1">
                          <p>Development Project</p>
                        </div>
                        <h5 className="serH4">IT Consultation Services</h5>
                      </div>
                      <div className="col-md-6 col-md-6 my-auto py-auto">
                        <img src={ITServices} className="w-100" alt="UI Design" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <h5 className="text_h5">
                      Navigating Technology to Drive Business Success
                    </h5>
                    <p className="py-1">
                      <small>
                        Our IT consultation expertise extends beyond technology
                        implementation to include process optimization, ensuring
                        your business operates at peak efficiency. We provide
                        strategic insights into aligning your IT infrastructure
                        with streamlined processes, driving productivity and
                        digital transformation. Partner with us for IT
                        consultation that not only advances your technological
                        capabilities but also enhances your operational
                        processes, setting a strong foundation for sustained
                        business success.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Add similar blocks for other content */}
          </div>
        </div>
      </div>
    </>
  );
};
export default OurServices;
