import logo from './logo.svg';
import './App.css';
import './styles.css';
import './css/qubiqonstyle.css';
import './css/styles.css';
import Header from './components/Header';
import ContentA from './components/ContentA';
import Footer from './components/Footer';
import OurServices from './components/OurServices';
import Careers from './components/Careers';
import AboutUs from './components/AboutUs';
function App() {

  return (
    <div className="App">
      <Header />
      <AboutUs />
      <OurServices />
      <Careers />
      <Footer />
    </div>
  );
}

export default App;
