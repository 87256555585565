import React from 'react';
import qubiqonLogo from "../assets/images/qubiqon.png";
const Footer = () => {
  return (
    <div className="d-flex flex-column h-100">
      <footer className="w-100 pt-4 pb-2 flex-shrink-0">
        <div className="container py-4">
          <div className="row gy-4 gx-5">
            <div className="col-lg-5 col-md-4">
              <img src={qubiqonLogo} className="w-75" alt="Qubiqon Logo" />
              <p className="small text-white">Exploring Cutting-Edge Technological Innovations</p>
            </div>
            <div className="col-lg-3 col-md-4">
              <h5 className="text-white mb-3">United States</h5>
              <ul className="list-unstyled text-white">
                <li><a href="#" className="text-white">2207 Crestview Ln</a></li>
                <li><a href="#" className="text-white">Wilmette</a></li>
                <li><a href="#" className="text-white">IL - 60091</a></li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-4">
              <h5 className="text-white mb-3">India</h5>
              <ul className="list-unstyled text-white">
                <li><a href="#" className="text-white">TFI Business BPO, Total Tower</a></li>
                <li><a href="#" className="text-white">Elamakkara, Edappally</a></li>
                <li><a href="#" className="text-white">Kochi, Kerala – 682024</a></li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <p className="ftrbottom_p">© 2023 Qubiqon. All rights reserved.</p>
            </div>
            <div className="col-md-4">
              <p className="ftrbottom_p">
                <ul className="list-inline">
                  <li className="list-inline-item text-white">Privacy Policy</li>
                  <li className="list-inline-item text-white">Terms of Service</li>
                  <li className="list-inline-item text-white">Cookie Policy</li>
                </ul>
              </p>
            </div>
            <div className="col-md-4">
              <p className="ftrbottom_p text-center">
                <ul className="list-inline">
                  <li className="list-inline-item text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      {/* SVG Path for the first social media icon */}
                    </svg>
                  </li>
                  <li className="list-inline-item text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="22" viewBox="0 0 19 22" fill="none">
                      {/* SVG Path for the second social media icon */}
                    </svg>
                  </li>
                  <li className="list-inline-item text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      {/* SVG Path for the third social media icon */}
                    </svg>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
