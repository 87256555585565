import React from "react";
import UIDesign from "../assets/images/UI Design.png";
const Carrers = () => {
  return (
    <div
      id="careers"
      className="container"
      style={{ position: "relative" }}
    >
      <div className="row">
        <div className="col-md-12" style={{ position: "relative" }}>
          <div className="row pb-5" style={{ position: "relative" }}>
            <div className="col-md-8">
              <h4 className="text_h4 px-4">Careers</h4>
              <p className="px-4">
                Join a Team Where Innovation Thrives - Be part of a dynamic
                company where your talent drives technological and business
                advancements. We offer a collaborative environment that nurtures
                growth and innovation. Embark on a career with us and shape the
                future – your journey towards making a significant impact starts
                here.
              </p>
            </div>
            <div className="col-md-3 flot-end pt-auto mt-auto">
              <button type="button" className="btn btnWhite float-end">
                See All{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                >
                  <rect
                    x="1"
                    y="0.5"
                    width="19"
                    height="19"
                    rx="9.5"
                    stroke="black"
                  />
                  <path
                    d="M14.0211 12.7727L13.798 7.10815C13.7915 7.00261 13.7643 6.89775 13.7179 6.79958C13.629 6.60182 13.4682 6.44104 13.2705 6.35212C13.1723 6.30574 13.0675 6.27855 12.9619 6.27209L7.29738 6.04892C7.19175 6.04476 7.08797 6.06145 6.99198 6.09803C6.89598 6.1346 6.80964 6.19036 6.73789 6.26211C6.59299 6.40701 6.5163 6.60827 6.52471 6.8216C6.53311 7.03493 6.62592 7.24286 6.78271 7.39965C6.9395 7.55644 7.14743 7.64925 7.36076 7.65765L11.0935 7.79906L6.41933 12.4732C6.27502 12.6175 6.19864 12.8179 6.20701 13.0304C6.21539 13.2429 6.30781 13.4499 6.46396 13.6061C6.62012 13.7623 6.8272 13.8547 7.03966 13.863C7.25212 13.8714 7.45256 13.795 7.59687 13.6507L12.271 8.9766L12.4124 12.7093C12.4161 12.815 12.4408 12.9206 12.4849 13.02C12.529 13.1194 12.5918 13.2106 12.6695 13.2883C12.7472 13.366 12.8384 13.4287 12.9378 13.4729C13.0371 13.517 13.1427 13.5416 13.2485 13.5454C13.3542 13.55 13.4582 13.5336 13.5544 13.4972C13.6506 13.4607 13.7371 13.405 13.809 13.3332C13.8808 13.2613 13.9365 13.1748 13.9729 13.0786C14.0094 12.9824 14.0257 12.8785 14.0211 12.7727Z"
                    fill="black"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Carrers;
